@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 2150px) { 
  html {
    font-size: 28px;
  }
}

@media (min-width: 3150px) { 
  html {
    font-size: 32px;
  }
}
